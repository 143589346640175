const Enviado = () => {
  return (
    <div className="bg-black flex w-full justify-center over items-center se:min-h-[110vh] xs:h-[100vh] md:h-[100vh] flex-col">
      <div className="w-full absolute top-0 p-2 xs:p-5 flex justify-center">
        <img
          className="w-[25%] xs:w-[8%]"
          src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1694024771/WALLET%20BLACK/Landing%20Comerciantes/Venus_sin_pastilla_blanca_1_fpdnd9.svg"
        />
      </div>

      <div
        className="w-[90%] xs:w-[50%] animate-up  md:w-[40%] xs:mt-[10%] lt:mt-[6%] border rounded-2xl flex flex-col items-center p-9 bg-black z-[100]"
        style={{ marginTop: 0 }}
      >
        <img
          className="w-[25%] xs:w-[12%] lt:w-[9%]"
          src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1674834976/WALLET%20BLACK/check-enviado_io9ejl.png"
          alt="check"
        />
        <h1 className="font-bold mt-[3%] xs:w-[80%] lt:w-[60%] text-center flex justify-center  text-white text-[22px] xs:text-[30px] lt:text-[40px]">
          ¡Tu #ProyectoBlack ya comenzó!
        </h1>
        <h3 className="text-white lt:w-[80%] text-center mt-[6%] font-[300] xs:text-[15px] lt:text-[18px]">
          A la brevedad un Ejecutivo Black se va a comunicar con vos para
          conocer tus ideas.
        </h3>
      </div>

      <img
        className="absolute w-[70%] xs:w-[50%] lt:w-[35%] xs:left-0 z-[10] right-[0%] bottom-[-10%] xs:top-0"
        src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693502010/WALLET%20BLACK/Landing%20beneficios/Hero/Capa_1_kyybap.svg"
        alt="bg"
      />
    </div>
  );
};

export default Enviado;