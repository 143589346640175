import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "./Pages/Landing";
import Enviado from "./Pages/Enviado";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/ProyectoEnviado" element={<Enviado />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;